// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-station-station-js": () => import("./../src/templates/station/station.js" /* webpackChunkName: "component---src-templates-station-station-js" */),
  "component---src-templates-station-station-schedule-js": () => import("./../src/templates/station/station-schedule.js" /* webpackChunkName: "component---src-templates-station-station-schedule-js" */),
  "component---src-templates-podcast-podcast-series-js": () => import("./../src/templates/podcast/podcast-series.js" /* webpackChunkName: "component---src-templates-podcast-podcast-series-js" */),
  "component---src-templates-presenter-presenter-js": () => import("./../src/templates/presenter/presenter.js" /* webpackChunkName: "component---src-templates-presenter-presenter-js" */),
  "component---src-templates-catchup-catchup-js": () => import("./../src/templates/catchup/catchup.js" /* webpackChunkName: "component---src-templates-catchup-catchup-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catchup-js": () => import("./../src/pages/catchup.js" /* webpackChunkName: "component---src-pages-catchup-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-podcasts-js": () => import("./../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-presenters-js": () => import("./../src/pages/presenters.js" /* webpackChunkName: "component---src-pages-presenters-js" */),
  "component---src-pages-radioplayer-js": () => import("./../src/pages/radioplayer.js" /* webpackChunkName: "component---src-pages-radioplayer-js" */),
  "component---src-pages-stations-js": () => import("./../src/pages/stations.js" /* webpackChunkName: "component---src-pages-stations-js" */)
}

